export const getMyProfileQuery = `
query getMyProfileQuery {
    getMyProfile {
        address
        email
        healthDataOptIn
        firstName
        landline
        languagePreference
        mobile
        notifyNewProducts
        postcode
        privacyAccepted
        sex
        sk2
        surname
        termsAccepted
        withdrawalOption
        PISVersion
    }
}`;

export const getConsentFormLinkQuery = `
query getConsentFormLinkQuery {
    getConsentFormLink
}`;

export const getVisitsQuery = `
query getEhrVisitsQuery {
    getEhrVisits {
        visitLocation
        startDate
        endDate
        department
        registrationType
        PK
    }
}
`;

export const getConditionsQuery = `
query getEhrPrimaryConditionsQuery {
    getEhrPrimaryConditions {
        primary
        ICDDescription
        ICDHierLevel1
        ICDHierLevel2
        ICDHierLevel3
        ICDHierLevel4
        effectiveDate
    }
}
`;

export const getEhrStatusQuery = `
query getEhrStatusQuery {
    getEhrStatus {
        dataHasArrived
        lastProcessedTime
        exportedDate
    }
}
`;
