import React from 'react';
import moment from 'moment';
import { RegType, Visits } from '../../../../../../core/types/health.types';
import { useTranslation } from 'react-i18next';
import { languagePreferenceSelector } from '../../../../../../store/participant/selectors';
import { useAppSelector } from '../../../../../../store';
import EventCard from '../../../../../../components/content/EventCard';

interface VisitsProps {
  visits: Visits[];
  page?: number;
  rowsPerPage?: number;
}

function VisitsList({ visits, page = 0, rowsPerPage = 10 }: VisitsProps) {
  const { t } = useTranslation('portal');
  const languagePreference = useAppSelector(languagePreferenceSelector);
  const format = languagePreference === 'en' ? 'DD MMM YYYY' : 'DD/MM/YYYY';

  const formatDate = (start: string, end: string) =>
    start === end ? moment(start).format(format) : moment(start).format(format) + ' - ' + moment(end).format(format);

  const getSubtitle = (registrationType: RegType) => {
    switch (registrationType) {
      case RegType.IN:
        return `(${t('health.visits.in')})`;
      case RegType.OUT:
        return `(${t('health.visits.out')})`;
      case RegType.ER:
        return `(${t('health.visits.er')})`;
      default:
        return '';
    }
  };
  return (
    <>
      {visits
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        .map(({ startDate, endDate, visitLocation, department, registrationType }: Visits, index: number) => (
          <EventCard
            key={index}
            date={formatDate(startDate, endDate)}
            title="Inkosi Albert Luthulu Central Hospital"
            subtitle={`${department} - ${visitLocation} ${getSubtitle(registrationType)}`}
          />
        ))}
    </>
  );
}

export default VisitsList;
