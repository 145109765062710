export const MOBILE_NUMBER_REGEX = /^(\+27|\+44)[0-9]{9,10}$/;
export const POSTAL_CODE_REGEX = /^[0-9]{4}$/;
export const TELEPHONE_NUMBER_REGEX = /^\+27[0-9]{9,10}$/;

const FORMS = {
  MOBILE_NUMBER_REGEX,
  TELEPHONE_NUMBER_REGEX,
  POSTAL_CODE_REGEX,
};
export default FORMS;
