import React from 'react';
import { useTrackedFormik } from '@omnigenbiodata/ui';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';

interface InitialValues {
  notifyNewProducts: boolean;
}

interface UpdateCommsFormProps {
  onSubmit: (values: InitialValues) => void;
  initialValues: InitialValues;
}

function UpdateCommsForm({ onSubmit, initialValues }: UpdateCommsFormProps) {
  const { t } = useTranslation('portal');

  const communicationFormik = useTrackedFormik(
    {
      initialValues,
      onSubmit,
    },
    'update-comms',
  );

  return (
    <Box mb={2}>
      <Paper variant="outlined">
        <Box p={4}>
          <form onSubmit={communicationFormik.handleSubmit}>
            <Typography variant="h6" align="left" gutterBottom>
              <strong>{t('preferences.comms.title')}</strong>
            </Typography>

            <Typography variant="body1" paragraph>
              {t('preferences.comms.para1')}
            </Typography>

            <FormControlLabel
              control={
                <Switch
                  aria-label="Comms Switch"
                  data-testid="comms-switch"
                  color="primary"
                  checked={communicationFormik.values.notifyNewProducts}
                  onChange={(event) => {
                    communicationFormik.setFieldValue(event.target.name, event.target.checked);
                    communicationFormik.submitForm();
                  }}
                  name="notifyNewProducts"
                />
              }
              label={t('preferences.comms.notifyProducts')}
            />
          </form>
        </Box>
      </Paper>
    </Box>
  );
}

export default UpdateCommsForm;
