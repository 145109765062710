import { GraphQLResponse } from './participant.types';

/* Health Data */

export enum RegType {
  IN = 'IN',
  OUT = 'OUT',
  ER = 'ER',
}

export interface Condition {
  primary: boolean;
  effectiveDate?: string;
  ICDDescription: string;
  ICDHierLevel1?: string;
  ICDHierLevel2?: string;
  ICDHierLevel3?: string;
  ICDHierLevel4?: string;
}

export interface Visits {
  visitLocation: string;
  startDate: string;
  endDate: string;
  department: string;
  registrationType: RegType;
  PK: string;
}

export interface EhrStatus {
  dataHasArrived: boolean;
  lastProcessedTime?: string;
  exportedDate?: string;
}

export interface GetVisitsResponse extends GraphQLResponse {
  data: { getEhrVisits: Visits[] };
}

export interface GetConditionsResponse extends GraphQLResponse {
  data: { getEhrPrimaryConditions: Condition[] };
}

export interface CheckEhrStatusResponse extends GraphQLResponse {
  data: { getEhrStatus: EhrStatus };
}
