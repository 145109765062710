import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import MainLayout from '../../../../../../layout/Main';
import ROUTES from '../../../../../../core/constants/routes.constant';
import { STUDY_EMAIL, STUDY_TEL } from '../../../../../../core/constants/content.constant';

import { useAppDispatch } from '../../../../../../store';
import { withdrawalReset } from '../../../../../../store/participant';

function WithdrawalIntroScene() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('portal');

  useEffect(() => {
    dispatch(withdrawalReset());
  }, [dispatch]);

  return (
    <MainLayout>
      <Box mb={8}>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          {t('withdrawal.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('withdrawal.para1')}
        </Typography>

        <Typography variant="body1" paragraph>
          {t('withdrawal.para2')}
        </Typography>

        <Typography variant="body1" paragraph>
          {t('withdrawal.para3')}
        </Typography>

        <Typography variant="body1" paragraph>
          {t('withdrawal.labels.email')}: {STUDY_EMAIL}
        </Typography>

        <Typography variant="body1" paragraph>
          {t('withdrawal.labels.or')}
        </Typography>

        <Typography variant="body1">
          {t('withdrawal.labels.telephone')}: {STUDY_TEL}.
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs sm={3}></Grid>
        <Grid item xs sm={6}>
          <Button
            variant="contained"
            color="error"
            size="large"
            fullWidth
            component={RouterLink}
            data-testid="withdrawal1-btn"
            to={ROUTES.portalWithdrawalOptions}
          >
            {t('withdrawal.button')}
          </Button>
        </Grid>
      </Grid>
    </MainLayout>
  );
}

export default WithdrawalIntroScene;
