import { RootState } from '../index';
import { ParticipantProfile, WithdrawParticipantParams } from '../../core/types/participant.types';
import { StoreStatuses } from '../../core/types/common.types';

export const profileSelector = (state: RootState): ParticipantProfile | null => state.participant.profile;

export const consentFormLinkSelector = (state: RootState): string | null => state.participant.consentLink;

export const healthDataOptInSelector = (state: RootState): boolean =>
  state.participant.profile?.healthDataOptIn || false;

export const notifyNewProductsSelector = (state: RootState): boolean =>
  state.participant.profile?.notifyNewProducts || false;

export const termsAcceptedSelector = (state: RootState): string => state.participant.profile?.termsAccepted || '';

export const privacyAcceptedSelector = (state: RootState): string => state.participant.profile?.privacyAccepted || '';

export const withdrawalOptionSelector = (state: RootState): string => state.participant.profile?.withdrawalOption || '';

export const PISVersionSelector = (state: RootState): string | undefined => state.participant.profile?.PISVersion;

export const languagePreferenceSelector = (state: RootState): string | undefined =>
  state.participant.profile?.languagePreference;

export const isBusySelector = (state: RootState): boolean => state.participant.status === StoreStatuses.BUSY;

export const isConsentFormBusySelector = (state: RootState): boolean =>
  state.participant.consentStatus === StoreStatuses.BUSY;

export const hasErrorSelector = (state: RootState): boolean => (state.participant.error ? true : false);

export const hasConsentFormErrorSelector = (state: RootState): boolean =>
  state.participant.consentError ? true : false;

export const withdrawalValuesSelector = (state: RootState): WithdrawParticipantParams | null =>
  state.participant.withdrawalValues;

export const hasWithdrawalErrorSelector = (state: RootState): any | null =>
  state.participant.withdrawalError ? true : false;

export const withdrawalErrorSelector = (state: RootState): any | null => state.participant.withdrawalError;

export const withdrawalResultSelector = (state: RootState): WithdrawParticipantParams | null =>
  state.participant.withdrawalResult;

export const usernameLookupSelector = (state: RootState): string | null => state.participant.usernameLookup;
