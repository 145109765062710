import { createSlice } from '@reduxjs/toolkit';
import { StoreStatuses } from '../../core/types/common.types';
import { getVisitsThunk, getConditionsThunk, getEhrStatusThunk } from './thunks';
import { Visits, EhrStatus, Condition } from '../../core/types/health.types';

export interface VisitsState {
  error: any;
  status: StoreStatuses;
  response: Visits[] | null;
}

export interface ConditionsState {
  error: any;
  status: StoreStatuses;
  response: Condition[] | null;
}

export interface HealthState {
  error: any;
  status: StoreStatuses;
  ehrStatus: EhrStatus;
  visits: VisitsState;
  conditions: ConditionsState;
}

// Initial State
export const initialState: HealthState = {
  status: StoreStatuses.IDLE,
  error: null,
  ehrStatus: {
    dataHasArrived: false,
  },
  visits: {
    error: null,
    response: null,
    status: StoreStatuses.IDLE,
  },
  conditions: {
    error: null,
    response: null,
    status: StoreStatuses.IDLE,
  },
};

// Health Slice
export const healthSlice = createSlice({
  name: 'health',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getVisitsThunk.pending.type, (state) => {
      state.visits.status = StoreStatuses.BUSY;
    });
    builder.addCase(getVisitsThunk.fulfilled.type, (state, action: any) => {
      state.visits.status = StoreStatuses.IDLE;
      state.visits.response = action.payload;
      state.visits.error = null;
    });
    builder.addCase(getVisitsThunk.rejected.type, (state, action: any) => {
      state.visits.status = StoreStatuses.ERROR;
      state.visits.error = action.error;
      state.visits.response = [];
    });

    builder.addCase(getConditionsThunk.pending.type, (state) => {
      state.conditions.status = StoreStatuses.BUSY;
    });
    builder.addCase(getConditionsThunk.fulfilled.type, (state, action: any) => {
      state.conditions.status = StoreStatuses.IDLE;
      state.conditions.response = action.payload;
      state.conditions.error = null;
    });
    builder.addCase(getConditionsThunk.rejected.type, (state, action: any) => {
      state.conditions.status = StoreStatuses.ERROR;
      state.conditions.error = action.error;
      state.conditions.response = [];
    });

    builder.addCase(getEhrStatusThunk.pending.type, (state) => {
      state.status = StoreStatuses.BUSY;
    });
    builder.addCase(getEhrStatusThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatuses.IDLE;
      state.ehrStatus = action.payload;
      state.error = null;
    });
    builder.addCase(getEhrStatusThunk.rejected.type, (state, action: any) => {
      state.status = StoreStatuses.ERROR;
      state.error = action.error;
    });
  },
});

// Action creators are generated for each case reducer function
export const { reset } = healthSlice.actions;
export * from './thunks';
export default healthSlice.reducer;
