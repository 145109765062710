import React from 'react';
import * as yup from 'yup';
import { FormInput, ButtonRow, useTrackedFormik } from '@omnigenbiodata/ui';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { POSTAL_CODE_REGEX, TELEPHONE_NUMBER_REGEX } from '../../../../../../core/constants/forms.constant';

interface InitialValues {
  address: string;
  sk2: string;
  landline: string;
  firstName: string;
  surname: string;
  postcode: string;
}

interface DetailsUpdateFormProps {
  onSubmit: (values: InitialValues) => void;
  initialValues: InitialValues;
}

function DetailsUpdateForm({ onSubmit, initialValues }: DetailsUpdateFormProps) {
  const { t } = useTranslation(['portal', 'validation']);
  const validationSchema = yup.object({
    address: yup.string().test(
      'oneOfRequired',
      t('addressRequired', {
        ns: 'validation',
      }),
      function () {
        return this.parent.postcode || this.parent.address;
      },
    ),
    landline: yup.string().matches(TELEPHONE_NUMBER_REGEX, t('landlineTelephoneFormat', { ns: 'validation' })),
    firstName: yup.string().required(
      t('firstNameRequired', {
        ns: 'validation',
      }),
    ),
    surname: yup.string().required(
      t('lastNameRequired', {
        ns: 'validation',
      }),
    ),
    postcode: yup
      .string()
      .test(
        'oneOfRequired',
        t('addressRequired', {
          ns: 'validation',
        }),
        function () {
          return this.parent.postcode || this.parent.address;
        },
      )
      .matches(POSTAL_CODE_REGEX, t('postalCodeFormat', { ns: 'validation' })),
  });

  const formik = useTrackedFormik(
    {
      initialValues,
      validationSchema,
      onSubmit,
    },
    'personal-details',
  );

  return (
    <Box mb={2}>
      <Paper variant="outlined">
        <Box p={4}>
          <form onSubmit={formik.handleSubmit}>
            <FormInput
              label={t('personal.labels.hospitalNum')}
              value={formik.values.sk2}
              error={formik.errors.sk2}
              touched={formik.touched.sk2}
              name="hospitalNum"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled
              startAdornment={<strong>KZ</strong>}
            />

            <FormInput
              label={t('personal.labels.firstName')}
              value={formik.values.firstName}
              error={formik.errors.firstName}
              touched={formik.touched.firstName}
              name="firstName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormInput
              label={t('personal.labels.surname')}
              value={formik.values.surname}
              error={formik.errors.surname}
              touched={formik.touched.surname}
              name="surname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormInput
              label={t('personal.labels.landline')}
              value={formik.values.landline}
              error={formik.errors.landline}
              touched={formik.touched.landline}
              name="landline"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormInput
              label={t('personal.labels.postcode')}
              value={formik.values.postcode}
              error={formik.errors.postcode}
              touched={formik.touched.postcode}
              name="postcode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormInput
              label={t('personal.labels.address')}
              value={formik.values.address}
              error={formik.errors.address}
              touched={formik.touched.address}
              name="address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ButtonRow forwardLabel={t('personal.button')} showBack={false} forwardColor="primary" buttonSize="large" />
          </form>
        </Box>
      </Paper>
    </Box>
  );
}

export default DetailsUpdateForm;
