export interface KeyValues {
  [key: string]: string | number | boolean | any[];
}

export enum StoreStatuses {
  BUSY = 'busy',
  IDLE = 'idle',
  ERROR = 'error',
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  INTERSEX = 'Intersex',
}

export enum PopulationGroup {
  AFRICAN = 'African',
  COLOURED = 'Coloured',
  INDOASIAN = 'Indian or Asian',
  WHITE = 'White',
  OTHER = 'Other',
}

export enum BloodSampleType {
  SUFFICIENT = 'Sufficient Sample',
  INSUFFICIENT = 'Insufficient Sample',
  NONE = 'No Blood',
}

export enum LanguageName {
  EN = 'English',
  ZU = 'isiZulu',
}

export enum LanguageCode {
  EN = 'en',
  ZU = 'zu',
}

export enum Department {
  ADDINGTON_ONCOLOGY = 'Addington Oncology',
  ANAESTHETICS = 'Anaesthetics',
  AUDIOLOGY = 'Audiology',
  BONE_MARROW_TRANSPLANT = 'Bone Marrow Transplant',
  BURNS = 'Burns',
  CARDIAC_SURGERY = 'Cardiac Surgery',
  CARDIOLOGY = 'Cardiology',
  CARDIOTHORACIC_SURGERY = 'Cardiothoracic Surgery',
  CLINICAL_PYSCHOLOGY = 'Clinical Psychology',
  CRANIOFACIAL = 'Craniofacial',
  CRANIOFACIAL_ONCOLOGY = 'Craniofacial Oncology',
  CRITICAL_CARE = 'Critical Care',
  DENTAL = 'Dental',
  DERMATOLOGY = 'Dermatology',
  DIETETICS = 'Dietetics',
  EAR_NOSE_AND_THROAT = 'Ear Nose and Throat',
  ELECTROCARDIOGRAPH = 'Electrocardiograph',
  GASTROENTEROLOGY = 'Gastroenterology',
  GENERAL_XRAY = 'General Xray',
  GERIATRICS = 'Geriatrics',
  GYNAECOLOGY = 'Gynaecology',
  HAEMATOLOGY = 'Haematology',
  MAMMOGRAPHY = 'Mammography',
  MAXILLO_FACIAL = 'Maxillo Facial',
  MEDICAL = 'Medical',
  MEDICINE = 'Medicine',
  METABOLIC_AND_ENDOCRINE = 'Metabolic and Endocrine',
  NEUROLOGY = 'Neurology',
  NEUROPHYSIOLOGY = 'Neurophysiology',
  NEUROSURGERY = 'Neurosurgery',
  NEUROSURGERY_ONCOLOGY = 'Neurosurgery Oncology',
  NUCLEAR_MEDICINE = 'Nuclear Medicine',
  OCCUPATIONAL_HEALTH = 'Occupational Health',
  OCCUPATIONAL_THERAPY = 'Occupational Therapy',
  ONCO_PALLATIVE_CARE_CLINIC = 'Onco Palliative Care Clinic',
  ONCOLOGY = 'Oncology',
  ONCOLOGY_UROLOGY_CLINIC = 'Oncology Urology Clinic',
  OPTHALMOLOGY = 'Ophthalmology',
  ORTHOPAEDIC = 'Orthopaedic',
  ORTHOPEDIC_ONCOLOGY = 'Orthopaedic Oncology',
  ORTHOPEDIC_TUMOR_CLINIC = 'Orthopaedic Tumour Clinic',
  ORTHOPAEDICS = 'Orthopaedics',
  PERITONEALDIALYSIS = 'Peritonealdialysis',
  PHYSIOTHERAPY = 'Physiotherapy',
  PLASTIC_DAY_PROCEDURE_D2E = 'Plastic Day Procedures D2E',
  PLASTIC_SURGERY = 'Plastic Surgery',
  PLASTIC_BREAST_RECONSTRUCTION = 'Plastics Breast Reconstruction',
  PLASTIC_EAR_RECONSTRUCTION = 'Plastics Ear Reconstruction',
  PULMONARY_FUNCTION_LAB = 'Pulmonary Function Lab',
  RADIATION_ONCOLOGY = 'Radiation Oncology',
  RADIOLOGY = 'Radiology',
  RENAL = 'Renal',
  RENAL_SURGERY = 'Renal Surgery',
  RESPIRATORY = 'Respiratory',
  RHEUMATOLOGY = 'Rheumatology',
  SPECIALISED_SURGERY = 'Specialised Surgery',
  SPEECH_THERAPY = 'Speech Therapy',
  STOMATHERAPY = 'Stomatherapy',
  SURGERY = 'Surgery',
  SURGICAL = 'Surgical',
  THORACIC_SURGERY = 'Thoracic Surgery',
  UROLOGY = 'Urology',
  UROLOGY_ONCOLOGY = 'Urology Oncology',
  VASCULAR = 'Vascular',
  VASCULAR_LAB = 'Vascular Lab',
}
