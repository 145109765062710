import { LanguageCode, Gender } from './common.types';

/* Profile */

export enum WithdrawalOption {
  COMPLETE = 'Complete',
  SHARING = 'SharingOnly',
  COLLECTION = 'CollectionOnly',
  SHARING_AND_COLLECTION = 'SharingAndCollection',
}

export interface ParticipantProfile {
  address: string;
  dob: string;
  email: string;
  firstName: string;
  healthDataOptIn: boolean;
  sk2: string;
  landline: string;
  languagePreference: LanguageCode;
  mobile: string;
  notifyNewProducts: boolean;
  notifyNewStudies: boolean;
  postcode: string;
  privacyAccepted: string;
  surname: string;
  sex: Gender;
  termsAccepted: string;
  withdrawalOption?: WithdrawalOption;
  PISVersion?: string;
}

/*  Params */
export interface UpdateMyProfileParams {
  address: string;
  firstName: string;
  landline: string;
  postcode: string;
  surname: string;
}

export interface AcceptLegalNoticesParams {
  notifyNewProducts: boolean;
  privacyAccepted: string;
  termsAccepted: string;
}

export interface WithdrawParticipantParams {
  withdrawalReason?: string;
  withdrawalOption?: string;
}

export interface GetUsernameParams {
  loginMethod: LoginMethod;
  loginParameter: string | null;
}

/* GraphQL Responses */
export interface GraphQLResponse {
  data: any;
  errors?: any;
}

export interface GetMyProfileResponse extends GraphQLResponse {
  data: { getMyProfile: ParticipantProfile };
}

export interface GetConsentFormLinkResponse extends GraphQLResponse {
  data: { getConsentFormLink: string };
}

export interface UpdateMyProfileResponse extends GraphQLResponse {
  data: { updateMyProfile?: ParticipantProfile };
}

export interface UpdateHealthDataOptInResponse extends GraphQLResponse {
  data: { updateHealthDataOptIn: ParticipantProfile };
}

export interface UpdateNotifyNewProductsResponse extends GraphQLResponse {
  data: { updateNotifyNewProducts: ParticipantProfile };
}

export interface UpdateAcceptLegalNoticesResponse extends GraphQLResponse {
  data: { acceptLegalNotices: ParticipantProfile };
}

export interface WithdrawParticipantResponse extends GraphQLResponse {
  data: { withdrawParticipant: true };
}

export interface GetUsernameResponse extends GraphQLResponse {
  data: { confirmLogin: string };
}

export enum LoginMethod {
  Email = 'Email',
  Mobile = 'Mobile',
}

export interface GraphQlErrorLocation {
  line: number;
  column: number;
  sourceName: string | null;
}

export interface GraphQlError {
  path: string[];
  data: string | null;
  errorType: string;
  errorInfo: string | null;
  locations: GraphQlErrorLocation[];
  message: string;
}
