import React from 'react';
import { useTrackedFormik } from '@omnigenbiodata/ui';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import MainLayout from '../../../../layout/Main';
import Markdown from 'react-markdown';
import ROUTES from '../../../../core/constants/routes.constant';
import CONTENT from '../../../../core/constants/content.constant';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

function PrivacyScene() {
  const { t } = useTranslation('privacy');
  const navigate = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: { agree: false },
      onSubmit: () => {
        navigate(ROUTES.consentCommunication);
      },
    },
    'consent-privacy',
  );

  return (
    <MainLayout>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          {t('title')}
        </Typography>

        {[...Array(7)].map((x, i) => (
          <Box mb={2} key={`privacy-${i}`}>
            <Paper variant="outlined">
              <Box p={2}>
                {t(`section${i + 1}.title`) && (
                  <Typography variant="h6" component="h2" paragraph>
                    {t(`section${i + 1}.title`)}
                  </Typography>
                )}
                <Typography variant="body2" component="span">
                  <Markdown linkTarget="_blank">
                    {t(`section${i + 1}.content`, {
                      webAddress: CONTENT.MICROSITE_URL,
                      webAddressFriendly: CONTENT.MICROSITE_URL_FRIENDLY,
                      studyEmail: CONTENT.STUDY_EMAIL,
                      studyTelephone: CONTENT.STUDY_TEL,
                      termsEmail: CONTENT.TERMS_EMAIL,
                      dpoEmail: CONTENT.DPO_EMAIL,
                    })}
                  </Markdown>
                </Typography>
              </Box>
            </Paper>
          </Box>
        ))}
        <Box mb={8}>
          <Button size="xl" variant="contained" color="primary" type="submit" fullWidth>
            {t('button')}
          </Button>
        </Box>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
            <Typography variant="body1">{t('help1.title')}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }} id="panel-content">
            <Typography variant="body1" component="span">
              <Markdown>{t('help1.content')}</Markdown>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </form>
    </MainLayout>
  );
}

export default PrivacyScene;
