import { API as AMPLIFY_API } from 'aws-amplify';
import { API as MOCK_AMPLIFY_API } from '../../__mocks__/aws-amplify';
import { getMyProfileQuery, getConsentFormLinkQuery } from '../graphql/queries';
import {
  acceptLegalNoticesMutation,
  updateMyProfileMutation,
  updateHealthDataOptInMutation,
  updateNotifyNewProductsMutation,
  withdrawStudyParticipantMutation,
  confirmLoginMutation,
} from '../graphql/mutations';

import {
  AcceptLegalNoticesParams,
  UpdateMyProfileParams,
  WithdrawParticipantParams,
  LoginMethod,
} from '../types/participant.types';

const API = process.env.REACT_APP_USE_MOCKS === 'true' ? MOCK_AMPLIFY_API : AMPLIFY_API;

const participantApi = {
  confirmLogin: async (loginMethod: LoginMethod, loginParameter: string | null): Promise<any> => {
    try {
      return await API.graphql({
        query: confirmLoginMutation,
        variables: {
          loginMethod,
          loginParameter,
        },
        authMode: 'AWS_IAM',
      });
    } catch (error) {
      return error;
    }
  },
  getMyProfile: async (): Promise<any> => {
    try {
      return await API.graphql({
        query: getMyProfileQuery,
      });
    } catch (error) {
      return error;
    }
  },
  getConsentFormLink: async (): Promise<any> => {
    try {
      return await API.graphql({
        query: getConsentFormLinkQuery,
      });
    } catch (error) {
      return error;
    }
  },
  updateMyProfile: async (values: UpdateMyProfileParams): Promise<any> => {
    try {
      return await API.graphql({
        query: updateMyProfileMutation,
        variables: {
          newProfile: {
            address: values.address,
            landline: values.landline,
            postcode: values.postcode,
            firstName: values.firstName,
            surname: values.surname,
          },
        },
      });
    } catch (error: any) {
      return error;
    }
  },
  updateHealthDataOptIn: async (value: boolean): Promise<any> => {
    try {
      return await API.graphql({
        query: updateHealthDataOptInMutation,
        variables: {
          healthDataOptIn: value,
        },
      });
    } catch (error: any) {
      return error;
    }
  },
  updateNotifyNewProducts: async (value: boolean): Promise<any> => {
    try {
      return await API.graphql({
        query: updateNotifyNewProductsMutation,
        variables: {
          notifyNewProducts: value,
        },
      });
    } catch (error: any) {
      return error;
    }
  },
  acceptLegalNotices: async (values: AcceptLegalNoticesParams): Promise<any> => {
    try {
      return await API.graphql({
        query: acceptLegalNoticesMutation,
        variables: {
          ...values,
        },
      });
    } catch (error: any) {
      return error;
    }
  },
  withdrawParticipant: async (values: WithdrawParticipantParams): Promise<any> => {
    try {
      return await API.graphql({
        query: withdrawStudyParticipantMutation,
        variables: {
          withdrawalOption: values.withdrawalOption,
          withdrawalReason: values.withdrawalReason,
        },
      });
    } catch (error: any) {
      return error;
    }
  },
};

export default participantApi;
