import React, { useState } from 'react';
import clsx from 'classnames';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useStyles } from './component.styles';
import { DMSALogo, Loader } from '@omnigenbiodata/ui';

interface SplashProps {
  welcomeText?: string;
  loadingText?: string;
  name?: string;
  isShowingSplash?: boolean;
}

function Splash({ welcomeText = 'Welcome', loadingText = 'Loading', name = '', isShowingSplash = false }: SplashProps) {
  const { classes } = useStyles();
  const [isFinishedSplash, setFinishedSplash] = useState(false);

  if (isFinishedSplash) {
    return null;
  }

  return (
    <div
      data-testid="splash-wrapper"
      className={clsx(classes.splash, {
        [classes.splashHidden]: !isShowingSplash,
        [classes.splashShown]: isShowingSplash,
      })}
      onTransitionEnd={() => {
        setFinishedSplash(true);
      }}
    >
      <DMSALogo size="md" />
      <div className={clsx(classes.personalContent, { [classes.visible]: name })}>
        <Box mt={3} mb={3}>
          <Typography variant="h5" align="center">
            {welcomeText}
          </Typography>

          <Typography variant="h3" align="center" paragraph color="primary">
            <strong>{name}</strong>
          </Typography>
        </Box>
        <Loader isVisible isInline label={loadingText} />
      </div>
    </div>
  );
}

export default Splash;
