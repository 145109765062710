export enum FEATURES {
  CONSENT_FORM = 'consentForm',
  USER_PREFERENCES = 'userPreferences',
  USER_WITHDRAWAL = 'userWithdrawal',
  USER_PROFILE = 'userProfile',
  PORTAL_PIS = 'portalPIS',
  PORTAL_VIDEO = 'portalVideo',
  PORTAL_FAQ = 'portalFAQ',
  EHR = 'ehrData',
  EHR_VISITS = 'ehrVisits',
  EHR_CONDITIONS = 'ehrConditions',
  EHR_MEDICATIONS = 'ehrMedications',
  EHR_ALLERGIES = 'ehrAllergies',
}

export const FEATURE_FLAGS = [
  { name: FEATURES.CONSENT_FORM, isActive: true },
  { name: FEATURES.USER_PREFERENCES, isActive: true },
  { name: FEATURES.USER_WITHDRAWAL, isActive: true },
  { name: FEATURES.USER_PROFILE, isActive: true },
  { name: FEATURES.PORTAL_PIS, isActive: true },
  { name: FEATURES.PORTAL_VIDEO, isActive: true },
  { name: FEATURES.PORTAL_FAQ, isActive: true },
  { name: FEATURES.EHR, isActive: true },
  { name: FEATURES.EHR_VISITS, isActive: true },
  { name: FEATURES.EHR_CONDITIONS, isActive: true },
  { name: FEATURES.EHR_MEDICATIONS, isActive: true },
  { name: FEATURES.EHR_ALLERGIES, isActive: true },
];

export const flagActive = (feature: string) => {
  return FEATURE_FLAGS.find(({ name }) => name === feature)?.isActive;
};
