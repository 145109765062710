import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { VimeoPlayer } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';

interface ParticipantVideoProps {
  title?: string;
}

function ParticipantVideo({ title = '' }: ParticipantVideoProps) {
  const { t } = useTranslation('video');
  return (
    <Paper variant="outlined">
      <Box p={4}>
        <VimeoPlayer videoUrl={t('url')} title={title} />
      </Box>
    </Paper>
  );
}

export default ParticipantVideo;
