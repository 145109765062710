import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FormRadioBlockGroup, ButtonRow, useTrackedFormik } from '@omnigenbiodata/ui';
import MainLayout from '../../../../../../layout/Main';
import ROUTES from '../../../../../../core/constants/routes.constant';
import { useAppSelector, useAppDispatch } from '../../../../../../store';
import { withdrawalForward } from '../../../../../../store/participant';
import { withdrawalValuesSelector } from '../../../../../../store/participant/selectors';

function WithdrawalOptionsScene() {
  const dispatch = useAppDispatch();
  const values = useAppSelector(withdrawalValuesSelector);
  const navigate = useNavigate();
  const { t } = useTranslation('portal');

  const validationSchema = yup.object().shape({
    withdrawalOption: yup.string().required(t('withdrawal2.subtitle')),
  });

  const formik = useTrackedFormik(
    {
      initialValues: {
        withdrawalOption: values?.withdrawalOption?.toString() || '',
      },
      validationSchema,
      onSubmit: (values) => {
        dispatch(withdrawalForward(values));
        navigate(ROUTES.portalWithdrawalReason);
      },
    },
    'withdrawal-options',
  );

  return (
    <MainLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={8}>
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            {t('withdrawal2.title')}
          </Typography>
          <Typography variant="h5" component="h2" align="center" gutterBottom>
            <strong>{t('withdrawal2.subtitle')}</strong>
          </Typography>

          <FormRadioBlockGroup
            name="withdrawalOption"
            labelCentered
            label={t('withdrawal2.para1')}
            onChange={formik.handleChange}
            value={formik.values.withdrawalOption}
            options={[
              {
                label: t('withdrawal2.options.SharingOnly.label'),
                value: 'SharingOnly',
                content: t('withdrawal2.options.SharingOnly.content'),
              },
              {
                label: t('withdrawal2.options.CollectionOnly.label'),
                value: 'CollectionOnly',
                content: t('withdrawal2.options.CollectionOnly.content'),
              },
              {
                label: t('withdrawal2.options.SharingAndCollection.label'),
                value: 'SharingAndCollection',
                content: t('withdrawal2.options.SharingAndCollection.content'),
              },
              {
                label: t('withdrawal2.options.Complete.label'),
                value: 'Complete',
                content: t('withdrawal2.options.Complete.content'),
              },
            ]}
            showOr={false}
            hideLabel={false}
            error={formik.errors.withdrawalOption}
            touched={formik.touched.withdrawalOption}
          />
        </Box>
        <ButtonRow
          backLabel={t('withdrawal2.back')}
          forwardLabel={t('withdrawal2.button')}
          forwardColor="error"
          buttonSize="large"
        />
      </form>
    </MainLayout>
  );
}

export default WithdrawalOptionsScene;
