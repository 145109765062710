import React from 'react';
import { useTrackedFormik } from '@omnigenbiodata/ui';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';

interface InitialValues {
  healthDataOptIn: boolean;
}

interface UpdateHealthFormFormProps {
  onSubmit: (values: InitialValues) => void;
  initialValues: InitialValues;
}

function UpdateHealthFormForm({ onSubmit, initialValues }: UpdateHealthFormFormProps) {
  const { t } = useTranslation('portal');

  const helpthOptionFormik = useTrackedFormik(
    {
      initialValues,
      onSubmit,
    },
    'update-health',
  );

  return (
    <Box mb={2}>
      <Paper variant="outlined">
        <Box p={4}>
          <form onSubmit={helpthOptionFormik.handleSubmit}>
            <Typography variant="h6" align="left" gutterBottom>
              <strong>{t('preferences.health.title')}</strong>
            </Typography>

            <Typography variant="body1" paragraph>
              {t('preferences.health.para1')}
            </Typography>
            <Typography variant="body1" paragraph>
              {t('preferences.health.para2')}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  aria-label="Health Switch"
                  data-testid="health-switch"
                  checked={helpthOptionFormik.values.healthDataOptIn}
                  onChange={(event) => {
                    helpthOptionFormik.setFieldValue(event.target.name, event.target.checked);
                    helpthOptionFormik.submitForm();
                  }}
                  name="healthDataOptIn"
                />
              }
              label={t('preferences.health.choice')}
            />
          </form>
        </Box>
      </Paper>
    </Box>
  );
}

export default UpdateHealthFormForm;
