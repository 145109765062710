import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  header: {
    flexGrow: 0,
    paddingRight: 0,
    paddingLeft: 0,
    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  hasMenu: {
    paddingRight: 105,
    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
    },
  },
  content: {
    flexGrow: 1,
  },
  footer: { flexGrow: 0 },
  MainName: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: { marginBottom: theme.spacing(10) },
  },
  logo: {
    display: 'block',
    textAlign: 'center',
    marginBottom: theme.spacing(8),
  },
}));
