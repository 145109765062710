import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { ButtonRow, FormTextarea, useTrackedFormik } from '@omnigenbiodata/ui';
import MainLayout from '../../../../../../layout/Main';
import ROUTES from '../../../../../../core/constants/routes.constant';
import { useAppSelector, useAppDispatch } from '../../../../../../store';
import { withdrawalForward } from '../../../../../../store/participant';
import { withdrawalValuesSelector } from '../../../../../../store/participant/selectors';

function WithdrawalReasonScene() {
  const dispatch = useAppDispatch();
  const values = useAppSelector(withdrawalValuesSelector);
  const navigate = useNavigate();
  const { t } = useTranslation('portal');

  const formik = useTrackedFormik(
    {
      initialValues: {
        withdrawalReason: values?.withdrawalReason ? values?.withdrawalReason?.toString() : '',
      },
      onSubmit: (values) => {
        dispatch(withdrawalForward(values));
        navigate(ROUTES.portalWithdrawalConfirm1);
      },
    },
    'withdrawal-reason',
  );

  return (
    <MainLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={8}>
          <Typography variant="h4" component="h1" align="center" gutterBottom data-testid="main-heading">
            {t('withdrawal3.title')}
          </Typography>
          <Typography variant="h5" component="h2" align="center" gutterBottom>
            <strong>{t('withdrawal3.subtitle')}</strong>
          </Typography>

          <Typography variant="body1" align="center" paragraph>
            {t('withdrawal3.para1')}
          </Typography>

          <FormTextarea
            label={t('withdrawal3.subtitle')}
            name="withdrawalReason"
            error={formik.errors.withdrawalReason}
            value={formik.values.withdrawalReason}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.withdrawalReason}
          />
        </Box>
        <ButtonRow
          backLabel={t('withdrawal3.back')}
          forwardLabel={t('withdrawal3.button')}
          forwardColor="error"
          buttonSize="large"
        />
      </form>
    </MainLayout>
  );
}

export default WithdrawalReasonScene;
