import React from 'react';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import { SectionBox, theme } from '@omnigenbiodata/ui';
import { Condition } from '../../../../../../core/types/health.types';
import { languagePreferenceSelector } from '../../../../../../store/participant/selectors';
import { useAppSelector } from '../../../../../../store';

interface SimpleProps {
  data: Condition[];
  type: string;
  page?: number;
  rowsPerPage?: number;
}

function ConditionsList({ data, type, page = 0, rowsPerPage = 10 }: SimpleProps) {
  const languagePreference = useAppSelector(languagePreferenceSelector);
  const format = languagePreference === 'en' ? 'DD MMM YYYY' : 'DD/MM/YYYY';

  return (
    <>
      {data
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        .map(
          (
            {
              effectiveDate,
              primary,
              ICDDescription,
              ICDHierLevel1,
              ICDHierLevel2,
              ICDHierLevel3,
              ICDHierLevel4,
            }: Condition,
            index: number,
          ) => (
            <SectionBox color="success" key={`${type}-${index}`}>
              <Typography variant="body1" component="h5">
                {moment(effectiveDate).format(format)}
              </Typography>
              <Typography variant="body1" fontWeight="bold" component="h6" color={theme.palette.info.dark}>
                Inkosi Albert Luthulu Central Hospital
              </Typography>
              <Typography
                mt={2}
                variant="subtitle1"
                component="h4"
                fontWeight="bold"
                color={theme.palette.primary.main}
              >
                {ICDDescription} {primary && ' - Primary'}
              </Typography>
              {ICDHierLevel1 && (
                <Typography pl={3} variant="subtitle2" component="p">
                  {ICDHierLevel1}
                </Typography>
              )}
              {ICDHierLevel2 && (
                <Typography pl={6} variant="subtitle2" component="p">
                  {ICDHierLevel2}
                </Typography>
              )}
              {ICDHierLevel3 && (
                <Typography pl={9} variant="subtitle2" component="p">
                  {ICDHierLevel3}
                </Typography>
              )}
              {ICDHierLevel4 && (
                <Typography pl={12} variant="subtitle2" component="p">
                  {ICDHierLevel4}
                </Typography>
              )}
            </SectionBox>
          ),
        )}
    </>
  );
}

export default ConditionsList;
