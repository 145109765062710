const ROOT = `/`;
const AUTH_ROOT = `${ROOT}auth/`;
const SPLASH_ROOT = `${ROOT}splash/`;
const CONSENT_ROOT = `${ROOT}consent/`;
const PORTAL_ROOT = `${ROOT}portal/`;
const LANGUAGE_ROOT = `${ROOT}lang/`;

export const ROUTES = {
  auth: AUTH_ROOT,
  authMultiFactor: `${AUTH_ROOT}mfa`,
  authSignOut: `${AUTH_ROOT}signout`,
  authNewPassword: `${AUTH_ROOT}newpassword`,
  authResetPassword: `${AUTH_ROOT}resetpassword`,
  authSubmitResetPassword: `${AUTH_ROOT}submitresetpassword`,
  consent: CONSENT_ROOT,
  consentPrivacy: `${CONSENT_ROOT}privacy`,
  consentCommunication: `${CONSENT_ROOT}communication`,
  language: `${LANGUAGE_ROOT}:lang`,
  portal: PORTAL_ROOT,
  portalPersonal: `${PORTAL_ROOT}personal`,
  portalPrefs: `${PORTAL_ROOT}prefs`,
  portalWithdrawal: `${PORTAL_ROOT}withdrawal`,
  portalWithdrawalOptions: `${PORTAL_ROOT}withdrawal/options`,
  portalWithdrawalReason: `${PORTAL_ROOT}withdrawal/reason`,
  portalWithdrawalConfirm1: `${PORTAL_ROOT}withdrawal/confirm1`,
  portalWithdrawalConfirm2: `${PORTAL_ROOT}withdrawal/confirm2`,
  portalWithdrawalEnd: `${PORTAL_ROOT}withdrawal/end`,
  portalInfo: `${PORTAL_ROOT}info`,
  portalVideo: `${PORTAL_ROOT}video`,
  portalHealth: `${PORTAL_ROOT}health`,
  splash: SPLASH_ROOT,
  root: ROOT,
};

export default ROUTES;
