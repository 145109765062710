import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Message } from '@omnigenbiodata/ui';
import MainLayout from '../../../../layout/Main';
import CONTENT from '../../../../core/constants/content.constant';
import ParticipantInfo from '../../../../components/content/ParticipantInfo';
import { PISVersionSelector } from '../../../../store/participant/selectors';
import { useAppSelector } from '../../../../store';

function InformationScene() {
  const { t } = useTranslation('portal');
  const PISVersion = useAppSelector(PISVersionSelector);
  return (
    <MainLayout>
      <Typography variant="h4" component="h1" align="center" paragraph>
        {t('information.title')}
      </Typography>
      {!PISVersion && (
        <div data-testid="pis-message">
          <Message severity="info">
            {t('information.message.line1')}
            {<br />}
            {<br />}
            {t('information.message.line2')}
            {<br />}
            {<br />}
            {t('information.message.line3')}
          </Message>
        </div>
      )}
      <ParticipantInfo
        address={CONTENT.STUDY_ADDRESS}
        web={CONTENT.MICROSITE_URL}
        email={CONTENT.STUDY_EMAIL}
        telephone={CONTENT.STUDY_TEL}
        version={PISVersion}
      />
    </MainLayout>
  );
}

export default InformationScene;
