import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { ButtonRow, useAuth, useTrackedFormik } from '@omnigenbiodata/ui';
import MainLayout from '../../../../../../layout/Main';
import { useAppSelector, useAppDispatch } from '../../../../../../store';
import { withdrawParticipantThunk } from '../../../../../../store/participant';
import {
  hasWithdrawalErrorSelector,
  isBusySelector,
  withdrawalValuesSelector,
  withdrawalResultSelector,
} from '../../../../../../store/participant/selectors';
import { WithdrawalOption } from '../../../../../../core/types/participant.types';

function WithdrawalConfirm2Scene() {
  const dispatch = useAppDispatch();
  const { signOut } = useAuth();
  const isBusy = useAppSelector(isBusySelector);
  const hasError = useAppSelector(hasWithdrawalErrorSelector);
  const withdrawalValues = useAppSelector(withdrawalValuesSelector);
  const withdrawalResult = useAppSelector(withdrawalResultSelector);
  const { t } = useTranslation('portal');

  const formik = useTrackedFormik(
    {
      initialValues: {
        ...withdrawalValues,
      },
      onSubmit: (values) => {
        dispatch(withdrawParticipantThunk(values));
      },
    },
    'withdrawal-confirm-2',
  );

  useEffect(() => {
    if (withdrawalResult?.withdrawalOption === WithdrawalOption.COMPLETE) {
      signOut();
    }
  }, [withdrawalResult, signOut]);

  if (withdrawalResult?.withdrawalOption === WithdrawalOption.COMPLETE) {
    return null;
  }

  return (
    <MainLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={8}>
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            {t('withdrawal5.title')}
          </Typography>
          {!withdrawalResult && (
            <>
              <Typography variant="h5" component="h2" align="center" gutterBottom>
                <strong>{t('withdrawal5.subtitle')}</strong>
              </Typography>
              <Typography variant="body1" align="center" paragraph>
                {t('withdrawal5.para1')}
              </Typography>
            </>
          )}
          {hasError && <Alert severity="error">{t('withdrawal5.error')}</Alert>}
          {withdrawalResult && <Alert severity="success">{t('withdrawal5.success')}</Alert>}
        </Box>
        {!withdrawalResult && (
          <ButtonRow
            backLabel={t('withdrawal5.back')}
            forwardLabel={t('withdrawal5.button')}
            forwardColor="error"
            buttonSize="large"
            isDisabled={isBusy}
          />
        )}
      </form>
    </MainLayout>
  );
}

export default WithdrawalConfirm2Scene;
