import { makeStyles } from 'tss-react/mui';
import { lighten } from '@mui/material';

export const useStyles = makeStyles()((theme) => ({
  paperDanger: {
    background: lighten(theme.palette.error.light, 0.8),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: lighten(theme.palette.error.main, 0.2),
  },
}));
