import React from 'react';
import { FlagsProvider } from 'react-feature-flags';
import { BrowserRouter as Router } from 'react-router-dom';
import Scenes from './scenes';
import { AuthProvider, ScrollToTop, EnvFlag, EnvFooter, PageTracker } from '@omnigenbiodata/ui';
import ENV from './core/constants/environment.constant';
import ROUTES from './core/constants/routes.constant';
import { FEATURE_FLAGS } from './core/constants/features.constants';

function App() {
  return (
    <FlagsProvider value={FEATURE_FLAGS}>
      <Router>
        <ScrollToTop />
        <AuthProvider authPath={ROUTES.auth} postLogoutPath={ROUTES.authSignOut}>
          <Scenes />
          <EnvFlag env={ENV.ENVIRONMENT as any} version={ENV.VERSION} />
          <EnvFooter env={ENV.ENVIRONMENT as any} version={ENV.FULL_VERSION} />
          <PageTracker />
        </AuthProvider>
      </Router>
    </FlagsProvider>
  );
}

export default App;
