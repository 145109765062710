/* People */
export const MOCK_BARCODE = '1234567';
export const MOCK_DOB_VALID = '1970-01-01';
export const MOCK_ERROR_MESSAGE = 'Invalid';
export const MOCK_HOSPITAL_NUMBER = '12345678';
export const MOCK_NATIONAL_ID = '9001024999012';
export const MOCK_INVALID_NATIONAL_ID = '9001024999014';
export const MOCK_INVALID_CITIZENSHIP_NATIONAL_ID = '9001024999312';
export const MOCK_SEX = 'Male';
export const MOCK_USERNAME = 'abc123';
export const EMAIL = 'valid@email.com';
export const UNREGISTERED_EMAIL = 'nonexistent@email.com';
export const INVALID_EMAIL = 'invalid@email.com';
export const VALID_PASSWORD = '123456';
export const INVALID_PASSWORD = '0101010101';
export const VALID_PASSWORD_NEW = '123457';
export const MOCK_FIRST_NAME = 'Jose';
export const MOCK_LAST_NAME = 'Gonzales';
export const FULL_NAME = 'John Rambo';
export const PHONE = '+46761022312';
export const MOBILE = '+277745454545';
export const POSTCODE = '1234';
export const ADDRESS = '1 Test Street';
export const TERMS_VERSION = 'v1.0, effective from 01 November 2021';
export const PRIVACY_VERSION = 'v2.0, effective from February 2023';
export const CONSENT_LINK = '/link/';

/* Auth */
export const ACCESS_TOKEN = '123cvb123';
export const SUB = 'abc123';
export const JWT = '123456';
export const CHALLENGE_NAME = 'SOFTWARE_TOKEN_MFA';
export const OTP = '123456';
export const OTP_SETUP_CODE = 'SETUPCODE';

/* Samples */
export const MOCK_COLLECTION_TIME = '2020-01-01 12:00';
