import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import { Message, Loader, useMatomo } from '@omnigenbiodata/ui';
import { StoreStatuses } from '../../../../../../core/types/common.types';
import { ReactElement, useState, cloneElement } from 'react';

interface EhrTabProps {
  data: {
    error: any;
    status: StoreStatuses;
    response: any;
  };
  showCaveat?: boolean;
  showCount?: boolean;
  icon: ReactElement;
  type: string;
  title: string;
  children: ReactElement;
}

const EhrTab = ({ data, showCaveat, showCount, type, title, icon, children }: EhrTabProps) => {
  const { trackEvent } = useMatomo();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRows] = useState(10);

  const { t } = useTranslation('portal');

  const handlePagination = (event: any, page: any) => {
    setPage(page);
    trackEvent({ category: type, action: `set-page-${page}` });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRows(parseInt(event.target.value, 10));
    setPage(0);
    trackEvent({
      category: type,
      action: `set-rows-${event.target.value}`,
    });
  };

  return (
    <div>
      <Typography mb={3} variant="h5" component="h3" paragraph display="flex" alignItems="center">
        {icon} <Box ml={1}>{title}</Box>
      </Typography>
      {showCaveat && (
        <Message severity="info">
          <ReactMarkdown>{t(`health.${type}.caveat`)}</ReactMarkdown>
        </Message>
      )}
      {data.error && <Message severity="error">{t('health.error')}</Message>}
      {data.response && (
        <>
          {showCount && (
            <Typography variant="body1" component="p" mb={3}>
              {data.response.length === 1
                ? t(`health.${type}.single`, { total: data.response.length })
                : t(`health.${type}.multiple`, {
                    total: data.response.length,
                  })}
            </Typography>
          )}
          {cloneElement(children, { page, rowsPerPage })}
          {data.response.length > 10 && (
            <TablePagination
              component="div"
              count={data.response.length}
              page={page}
              onPageChange={handlePagination}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{ id: 'change-page' }}
            />
          )}
        </>
      )}
      <Loader isVisible={data.status === StoreStatuses.BUSY} label={t('health.loader') as string} />
    </div>
  );
};

export default EhrTab;
