import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Markdown from 'react-markdown';
import * as yup from 'yup';
import { RiMailLine, RiPhoneLine } from 'react-icons/ri';
import Typography from '@mui/material/Typography';
import { FormInput, Loader, useTrackedFormik, useMatomo } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';

import MainLayout from '../../../../layout/Main';
import { useAuth, AuthErrorCodes } from '@omnigenbiodata/ui';

import { MOBILE_NUMBER_REGEX } from '../../../../core/constants/forms.constant';
import CONTENT from '../../../../core/constants/content.constant';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { confirmLoginThunk, resetUsernameLookup } from '../../../../store/participant';
import { usernameLookupSelector, isBusySelector, hasErrorSelector } from '../../../../store/participant/selectors';
import { LoginMethod } from '../../../../core/types/participant.types';

function LoginScene() {
  const { trackPageView } = useMatomo();
  const { signIn, hasError, isBusy, errorType, isSignedOut, isTimedOut } = useAuth();
  const dispatch = useAppDispatch();
  const username = useAppSelector(usernameLookupSelector);
  const usernameLookupBusy = useAppSelector(isBusySelector);
  const hasParticipantError = useAppSelector(hasErrorSelector);
  const { t } = useTranslation(['portal', 'validation']);
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('emailInvalid', { ns: 'validation' }))
      .test('oneOfRequired', t('contactDetailsRequired', { ns: 'validation' }), function () {
        return this.parent.email || this.parent.mobile;
      }),
    mobile: yup
      .string()
      .matches(new RegExp(MOBILE_NUMBER_REGEX), t('mobileTelephoneFormat', { ns: 'validation' }))
      .test('oneOfRequired', t('contactDetailsRequired', { ns: 'validation' }), function () {
        return this.parent.email || this.parent.mobile;
      }),
  });

  const formik = useTrackedFormik(
    {
      initialValues: { email: '', mobile: '' },
      validationSchema,
      onSubmit: (values) => {
        dispatch(
          confirmLoginThunk({
            loginMethod: values.email ? LoginMethod.Email : LoginMethod.Mobile,
            loginParameter: values.email || values.mobile,
          }),
        );
      },
    },
    'login',
  );

  useEffect(() => {
    if (username) {
      dispatch(resetUsernameLookup());
      signIn(username, undefined, {
        loginMethod: formik.values.email ? LoginMethod.Email : LoginMethod.Mobile,
      });
    }
  }, [signIn, username, dispatch, formik]);

  useEffect(() => {
    trackPageView({
      href: `${window.location.href}login`,
    });
  }, [trackPageView]);

  return (
    <>
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <Box mb={8}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              {t('login.title')}
            </Typography>
            <Typography paragraph variant="body1" align="center">
              {t('login.intro')}
            </Typography>
          </Box>

          {hasParticipantError && (
            <Box mb={2}>
              <Alert severity="error">{t('login.error')}</Alert>
            </Box>
          )}

          {isSignedOut && !isTimedOut && (
            <Box mb={2}>
              <Alert severity="info">{t('login.isSignedOut')}</Alert>
            </Box>
          )}

          {isSignedOut && isTimedOut && (
            <Box mb={2}>
              <Alert severity="info">{t('login.isTimedOut')}</Alert>
            </Box>
          )}

          {hasError && !hasParticipantError && errorType.code === AuthErrorCodes.NOT_AUTHORIZED && (
            <Box mb={2}>
              <Alert severity="error">{t('login.errorPasscode')}</Alert>
            </Box>
          )}

          {hasError && !hasParticipantError && errorType.code === AuthErrorCodes.INVALID_EXCEPTION && (
            <Box mb={2}>
              <Alert severity="error">
                {t('login.errorTemporary', {
                  studyEmail: CONTENT.STUDY_EMAIL,
                })}
              </Alert>
            </Box>
          )}

          <Box mb={8}>
            <FormInput
              error={formik.errors.email}
              name="email"
              label={t('login.email')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.email}
              value={formik.values.email}
              startAdornment={<RiMailLine fontSize="large" />}
            />
            <Box mt={2} mb={2}>
              <Typography variant="body1" align="center">
                {t('login.or')}
              </Typography>
            </Box>
            <FormInput
              error={formik.errors.mobile}
              name="mobile"
              label={t('login.telephone')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.mobile}
              value={formik.values.mobile}
              startAdornment={<RiPhoneLine fontSize="large" />}
            />
          </Box>
          <Box mb={8}>
            <Grid container spacing={2}>
              <Hidden xsDown>
                <Grid item xs={12} md={6} />
              </Hidden>

              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  data-testid="forward-button"
                >
                  {t('login.button')}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="body1">{t('login.helpTitle')}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: 'column' }}>
              <Typography variant="body1" component="span" paragraph>
                <Markdown>
                  {t('login.helpText', {
                    studyEmail: CONTENT.STUDY_EMAIL,
                    studyTelephone: CONTENT.STUDY_TEL,
                  })}
                </Markdown>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </form>
      </MainLayout>
      <Loader isVisible={username !== null || isBusy || usernameLookupBusy} label={t('authenticating')} />
    </>
  );
}

export default LoginScene;
