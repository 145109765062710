export const confirmLoginMutation = `
mutation confirmLoginMutation($loginMethod: LoginMethod!, $loginParameter: String!)  {
  confirmLogin(loginMethod: $loginMethod, loginParameter:$loginParameter)
}`;

export const withdrawStudyParticipantMutation = `
mutation withdrawParticipant($withdrawalOption: Withdrawal!, $withdrawalReason: String!) {
    withdrawParticipant(withdrawalOption: $withdrawalOption, withdrawalReason: $withdrawalReason) {
      address
      email
      healthDataOptIn
      firstName
      landline
      languagePreference
      mobile
      notifyNewProducts
      postcode
      privacyAccepted
      sex
      sk2
      surname
      termsAccepted
      withdrawalOption
    },
}
`;

export const acceptLegalNoticesMutation = `
mutation acceptLegalNoticesMutation($notifyNewProducts: Boolean!, $termsAccepted: String!, $privacyAccepted: String!) {
  acceptLegalNotices(notifyNewProducts: $notifyNewProducts, termsAccepted: $termsAccepted, privacyAccepted: $privacyAccepted) {
      email
      postcode
      mobile
      landline
      address
      notifyNewProducts
      termsAccepted
      privacyAccepted
      healthDataOptIn
      firstName
      languagePreference
      sex
      sk2
      surname
    }
  }
`;

export const updateHealthDataOptInMutation = `
mutation updateHealthDataOptInMutation($healthDataOptIn: Boolean!) {
    updateHealthDataOptIn(healthDataOptIn: $healthDataOptIn) {
      email
      postcode
      mobile
      landline
      address
      notifyNewProducts
      termsAccepted
      privacyAccepted
      healthDataOptIn
      firstName
      languagePreference
      sex
      sk2
      surname
    }
  }
`;

export const updateNotifyNewProductsMutation = `
mutation updateNotifyNewProductsMutation($notifyNewProducts: Boolean!) {
  updateNotifyNewProducts(notifyNewProducts: $notifyNewProducts) {
    email
    postcode
    mobile
    landline
    address
    notifyNewProducts
    termsAccepted
    privacyAccepted
    healthDataOptIn
    firstName
    languagePreference
    sex
    sk2
    surname
    }
  }
`;

export const updateMyProfileMutation = `
mutation updateMyProfile($newProfile: MyProfileInput!) {
    updateMyProfile(newProfile: $newProfile) {
      email
      postcode
      mobile
      landline
      address
      notifyNewProducts
      termsAccepted
      privacyAccepted
      healthDataOptIn
      firstName
      languagePreference
      sex
      sk2
      surname
    }
  }
`;
