import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import { PageWrapper, PartnerStrip, DMSALogo } from '@omnigenbiodata/ui';
import clsx from 'classnames';

import { useStyles } from './component.styles';
import { PARTNER_LOGOS } from '../../core/constants/content.constant';
import SideMenu from '../../components/navigation/SideMenu';
import { useAuth, useMatomo } from '@omnigenbiodata/ui';
import { healthDataOptInSelector } from '../../store/participant/selectors';
import { useAppSelector } from '../../store';

interface MainLayoutProps {
  children: ReactNode;
  title?: string;
}

function MainLayout({ children, title = '' }: MainLayoutProps) {
  const { trackEvent } = useMatomo();
  const { classes } = useStyles();
  const { isAuthenticated } = useAuth();
  const [isMenuOpenFlag, setIsMenuOpenFlag] = useState(false);
  const healthDataOptIn = useAppSelector(healthDataOptInSelector);

  const handleToggleMenu = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsMenuOpenFlag(open);
    if (open) {
      trackEvent({ category: 'nav', action: `open-side-menu` });
    }
  };

  return (
    <>
      {isAuthenticated && (
        <SideMenu isOpen={isMenuOpenFlag} onToggle={handleToggleMenu} showHealthOption={healthDataOptIn} />
      )}
      <PageWrapper>
        <div
          className={clsx(classes.header, {
            [classes.hasMenu]: isAuthenticated,
          })}
        >
          <Link to="/" className={classes.logo}>
            <DMSALogo size="responsive" />
          </Link>
        </div>

        {title && (
          <Typography className={classes.MainName} variant="h2" component="h1" align="center">
            {title}
          </Typography>
        )}

        <Fade in timeout={500}>
          <div className={classes.content}>{children}</div>
        </Fade>
        <div className={classes.footer}>
          <PartnerStrip logos={PARTNER_LOGOS} />
        </div>
      </PageWrapper>
    </>
  );
}

export default MainLayout;
