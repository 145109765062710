import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainLayout from '../../layout/Main';
import { CURRENT_TERMS_VERSION, CURRENT_PRIVACY_VERSION, SPLASH_TIMEOUT } from '../../core/constants/content.constant';
import ROUTES from '../../core/constants/routes.constant';
import {
  isBusySelector,
  languagePreferenceSelector,
  termsAcceptedSelector,
  privacyAcceptedSelector,
  profileSelector,
} from '../../store/participant/selectors';
import { getMyProfileThunk } from '../../store/participant';
import { useAppDispatch, useAppSelector } from '../../store';
import Splash from '../../components/content/Splash';

function SplashScene() {
  const { t, i18n } = useTranslation('portal');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isBusy = useAppSelector(isBusySelector);
  const profile = useAppSelector(profileSelector);
  const languagePreference = useAppSelector(languagePreferenceSelector);
  const termsAccepted = useAppSelector(termsAcceptedSelector);
  const privacyAccepted = useAppSelector(privacyAcceptedSelector);

  useEffect(() => {
    dispatch(getMyProfileThunk());
  }, [dispatch]);

  useEffect(() => {
    if (!isBusy && profile) {
      i18n.changeLanguage(languagePreference);
      setTimeout(() => {
        if (termsAccepted !== CURRENT_TERMS_VERSION || privacyAccepted !== CURRENT_PRIVACY_VERSION) {
          navigate(ROUTES.consent);
        } else {
          navigate(ROUTES.root);
        }
      }, SPLASH_TIMEOUT);
    }
  }, [navigate, i18n, isBusy, languagePreference, privacyAccepted, profile, termsAccepted]);

  return (
    <MainLayout title="">
      <Splash welcomeText={t('welcome')} loadingText={t('loading')} isShowingSplash name={profile?.firstName} />
    </MainLayout>
  );
}

export default SplashScene;
