import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { Flags } from 'react-feature-flags';
import { RiUser3Line, RiFolderInfoLine, RiVideoLine, RiSettings5Line, RiHealthBookLine } from 'react-icons/ri';
import MainLayout from '../../../../layout/Main';
import ENVIRONMENT from '../../../../core/constants/environment.constant';
import ROUTES from '../../../../core/constants/routes.constant';
import { FEATURES } from '../../../../core/constants/features.constants';
import { useAppSelector } from '../../../../store';
import { healthDataOptInSelector } from '../../../../store/participant/selectors';

function HomeScene() {
  const { t } = useTranslation('portal');
  const healthDataOptIn = useAppSelector(healthDataOptInSelector);

  return (
    <MainLayout title={t('title')}>
      {healthDataOptIn && (
        <Flags authorizedFlags={[FEATURES.EHR]}>
          <Box mb={2}>
            <Button
              fullWidth
              color="primary"
              size="xl"
              variant="contained"
              component={Link}
              data-testid="health-btn"
              to={ROUTES.portalHealth}
            >
              <RiHealthBookLine fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
              {t('home.healthInfoLink')}
            </Button>
          </Box>
        </Flags>
      )}
      <Flags authorizedFlags={[FEATURES.USER_PROFILE]}>
        <Box mb={2}>
          <Button
            fullWidth
            color="primary"
            size="xl"
            variant="contained"
            component={Link}
            data-testid="personal-btn"
            to={ROUTES.portalPersonal}
          >
            <RiUser3Line fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
            {t('home.personalInfoLink')}
          </Button>
        </Box>
      </Flags>
      <Flags authorizedFlags={[FEATURES.USER_PREFERENCES]}>
        <Box mb={2}>
          <Button
            fullWidth
            size="xl"
            variant="contained"
            component={Link}
            data-testid="preferences-btn"
            to={ROUTES.portalPrefs}
          >
            <RiSettings5Line fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
            {t('home.preferencesLink')}
          </Button>
        </Box>
      </Flags>
      <Flags authorizedFlags={[FEATURES.PORTAL_PIS]}>
        <Box mb={2}>
          <Button fullWidth size="xl" variant="outlined" component={Link} data-testid="pis-btn" to={ROUTES.portalInfo}>
            <RiFolderInfoLine fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
            {t('home.pisLink')}
          </Button>
        </Box>
      </Flags>
      <Flags authorizedFlags={[FEATURES.PORTAL_VIDEO]}>
        <Box mb={2}>
          <Button
            fullWidth
            size="xl"
            variant="outlined"
            component={Link}
            data-testid="video-btn"
            to={ROUTES.portalVideo}
          >
            <RiVideoLine fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
            {t('home.videoLink')}
          </Button>
        </Box>
      </Flags>
      {ENVIRONMENT.ENVIRONMENT && (
        <Typography variant="body2" align="center" paragraph style={{ marginTop: 0, marginBottom: 70 }}>
          {ENVIRONMENT.ENVIRONMENT} - Version {ENVIRONMENT.VERSION}
        </Typography>
      )}
    </MainLayout>
  );
}

export default HomeScene;
