import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AuthRoute } from '@omnigenbiodata/ui';

import AuthenticationScene from './Authentication';
import SignOutScene from './Authentication/scenes/SignOut';
import LanguageSetScene from './LanguageSet';
import SplashScene from './Splash';
import ConsentScene from './Consent';
import TermsScene from './Consent/scenes/Terms';
import PrivacyScene from './Consent/scenes/Privacy';
import CommsScene from './Consent/scenes/Comms';
import PortalScene from './Portal';
import PersonalScene from './Portal/scenes/Personal';
import PreferencesScene from './Portal/scenes/Preferences';
import HomeScene from './Portal/scenes/Home';
import WithdrawalScene from './Portal/scenes/Withdrawal';
import WithdrawalIntroScene from './Portal/scenes/Withdrawal/scenes/WithdrawalIntro';
import WithdrawalOptionsScene from './Portal/scenes/Withdrawal/scenes/WithdrawalOptions';
import WithdrawalReasonScene from './Portal/scenes/Withdrawal/scenes/WithdrawalReason';
import WithdrawalConfirm1Scene from './Portal/scenes/Withdrawal/scenes/WithdrawalConfirm1';
import WithdrawalConfirm2Scene from './Portal/scenes/Withdrawal/scenes/WithdrawalConfirm2';
import InformationScene from './Portal/scenes/Information';
import VideoScene from './Portal/scenes/Video';
import HealthScene from './Portal/scenes/Health';
import ROUTES from '../core/constants/routes.constant';

function Scenes() {
  return (
    <Routes>
      <Route path={ROUTES.language} element={<LanguageSetScene />} />
      <Route path={ROUTES.authSignOut} element={<SignOutScene />} />
      <Route path={ROUTES.auth} element={<AuthenticationScene />} />
      <Route path={ROUTES.consent} element={<AuthRoute path={ROUTES.consent} element={ConsentScene} />}>
        <Route path={ROUTES.consentCommunication} element={<CommsScene />} />
        <Route path={ROUTES.consentPrivacy} element={<PrivacyScene />} />
        <Route path={ROUTES.consent} element={<TermsScene />} />
      </Route>
      <Route path={ROUTES.splash} element={<AuthRoute path={ROUTES.splash} element={SplashScene} />} />
      <Route path={ROUTES.portal} element={<AuthRoute path={ROUTES.portal} element={PortalScene} />}>
        <Route path={ROUTES.portal} element={<HomeScene />} />
        <Route path={ROUTES.portalPersonal} element={<PersonalScene />} />
        <Route path={ROUTES.portalPrefs} element={<PreferencesScene />} />
        <Route path={ROUTES.portalWithdrawal} element={<WithdrawalScene />}>
          <Route path={ROUTES.portalWithdrawal} element={<WithdrawalIntroScene />} />
          <Route path={ROUTES.portalWithdrawalOptions} element={<WithdrawalOptionsScene />} />
          <Route path={ROUTES.portalWithdrawalReason} element={<WithdrawalReasonScene />} />
          <Route path={ROUTES.portalWithdrawalConfirm1} element={<WithdrawalConfirm1Scene />} />
          <Route path={ROUTES.portalWithdrawalConfirm2} element={<WithdrawalConfirm2Scene />} />
        </Route>
        <Route path={ROUTES.portalInfo} element={<InformationScene />} />
        <Route path={ROUTES.portalVideo} element={<VideoScene />} />
        <Route path={ROUTES.portalHealth} element={<HealthScene />} />
      </Route>
      <Route path="*" element={<Navigate to={ROUTES.portal} replace />} />
    </Routes>
  );
}

export default Scenes;
