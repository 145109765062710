import { createSlice } from '@reduxjs/toolkit';
import { ParticipantProfile, WithdrawParticipantParams } from '../../core/types/participant.types';
import { StoreStatuses } from '../../core/types/common.types';
import {
  getMyProfileThunk,
  getConsentFormLinkThunk,
  updateMyProfileThunk,
  updateHealthDataOptInThunk,
  acceptLegalNoticesThunk,
  withdrawParticipantThunk,
  updateNotifyNewProductsThunk,
  confirmLoginThunk,
} from './thunks';

export interface ParticipantState {
  error: any;
  profile: ParticipantProfile | null;
  consentLink: string | null;
  status: StoreStatuses;
  consentStatus: StoreStatuses;
  consentError: any;
  withdrawalValues: WithdrawParticipantParams | null;
  withdrawalError: string | null;
  withdrawalStatus: StoreStatuses;
  withdrawalResult: WithdrawParticipantParams | null;
  usernameLookup: string | null;
}

// Initial State
export const initialState: ParticipantState = {
  status: StoreStatuses.IDLE,
  error: null,
  profile: null,
  consentLink: null,
  consentStatus: StoreStatuses.IDLE,
  consentError: null,
  withdrawalValues: null,
  withdrawalError: null,
  withdrawalStatus: StoreStatuses.IDLE,
  withdrawalResult: null,
  usernameLookup: null,
};

// Participant Slice
export const participantSlice = createSlice({
  name: 'participant',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
    resetUsernameLookup: (state) => ({
      ...state,
      usernameLookup: null,
    }),
    resetStatus: (state) => ({
      ...state,
      status: initialState.status,
      error: initialState.error,
    }),
    withdrawalForward: (state, action) => ({
      ...state,
      withdrawalValues: { ...state.withdrawalValues, ...action.payload },
      withdrawalStatus: StoreStatuses.IDLE,
      withdrawalError: null,
      withdrawalResult: null,
    }),
    withdrawalReset: (state) => ({
      ...state,
      withdrawalValues: null,
      withdrawalStatus: StoreStatuses.IDLE,
      withdrawalError: null,
      withdrawalResult: null,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(confirmLoginThunk.pending.type, (state) => {
      state.status = StoreStatuses.BUSY;
    });
    builder.addCase(confirmLoginThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatuses.IDLE;
      state.usernameLookup = action.payload;
      state.error = null;
    });
    builder.addCase(confirmLoginThunk.rejected.type, (state, action: any) => {
      state.status = StoreStatuses.ERROR;
      state.error = action.error;
      state.profile = null;
    });

    builder.addCase(getMyProfileThunk.pending.type, (state) => {
      state.status = StoreStatuses.BUSY;
    });
    builder.addCase(getMyProfileThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatuses.IDLE;
      state.profile = action.payload;
      state.error = null;
    });
    builder.addCase(getMyProfileThunk.rejected.type, (state, action: any) => {
      state.status = StoreStatuses.ERROR;
      state.error = action.error;
      state.profile = null;
    });
    builder.addCase(getConsentFormLinkThunk.pending.type, (state) => {
      state.consentStatus = StoreStatuses.BUSY;
    });
    builder.addCase(getConsentFormLinkThunk.fulfilled.type, (state, action: any) => {
      state.consentStatus = StoreStatuses.IDLE;
      state.consentLink = action.payload;
      state.error = null;
    });
    builder.addCase(getConsentFormLinkThunk.rejected.type, (state, action: any) => {
      state.consentStatus = StoreStatuses.ERROR;
      state.consentError = action.payload;
      state.consentLink = null;
    });
    builder.addCase(updateMyProfileThunk.pending.type, (state) => {
      state.status = StoreStatuses.BUSY;
    });
    builder.addCase(updateMyProfileThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatuses.IDLE;
      state.profile = action.payload;
      state.error = null;
    });
    builder.addCase(updateMyProfileThunk.rejected.type, (state, action: any) => {
      state.status = StoreStatuses.ERROR;
      state.error = action.payload;
    });
    builder.addCase(updateHealthDataOptInThunk.pending.type, (state) => {
      state.status = StoreStatuses.BUSY;
    });
    builder.addCase(updateHealthDataOptInThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatuses.IDLE;
      state.profile = action.payload;
      state.error = null;
    });
    builder.addCase(updateHealthDataOptInThunk.rejected.type, (state, action: any) => {
      state.status = StoreStatuses.ERROR;
      state.error = action.error;
    });
    builder.addCase(updateNotifyNewProductsThunk.pending.type, (state) => {
      state.status = StoreStatuses.BUSY;
    });
    builder.addCase(updateNotifyNewProductsThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatuses.IDLE;
      state.profile = action.payload;
      state.error = null;
    });
    builder.addCase(updateNotifyNewProductsThunk.rejected.type, (state, action: any) => {
      state.status = StoreStatuses.ERROR;
      state.error = action.error;
    });
    builder.addCase(acceptLegalNoticesThunk.pending.type, (state) => {
      state.status = StoreStatuses.BUSY;
    });
    builder.addCase(acceptLegalNoticesThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatuses.IDLE;
      state.profile = action.payload;
      state.error = null;
    });
    builder.addCase(acceptLegalNoticesThunk.rejected.type, (state, action: any) => {
      state.status = StoreStatuses.ERROR;
      state.error = action.error;
    });
    builder.addCase(withdrawParticipantThunk.pending.type, (state) => {
      state.withdrawalStatus = StoreStatuses.BUSY;
      state.withdrawalResult = null;
    });
    builder.addCase(withdrawParticipantThunk.fulfilled.type, (state, action: any) => {
      state.withdrawalStatus = StoreStatuses.IDLE;
      state.withdrawalError = null;
      state.withdrawalResult = state.withdrawalValues;
      state.profile = action.payload;
      state.withdrawalValues = null;
    });
    builder.addCase(withdrawParticipantThunk.rejected.type, (state, action: any) => {
      state.withdrawalStatus = StoreStatuses.ERROR;
      state.withdrawalError = action.error;
      state.withdrawalResult = null;
    });
  },
});

// Action creators are generated for each case reducer function
export const { reset, resetStatus, withdrawalForward, withdrawalReset, resetUsernameLookup } = participantSlice.actions;
export * from './thunks';
export default participantSlice.reducer;
