import React from 'react';
import Typography from '@mui/material/Typography';
import { SectionBox, theme } from '@omnigenbiodata/ui';

interface EventCardProps {
  date: string;
  title: string;
  subtitle?: string;
  text?: string;
}

function EventCard({ date, title, subtitle, text }: EventCardProps) {
  return (
    <SectionBox color="success">
      <Typography variant="body1" component="h5">
        {date}
      </Typography>
      <Typography variant="body1" fontWeight="bold" component="h6" color={theme.palette.info.dark}>
        {title}
      </Typography>
      {subtitle && (
        <Typography mt={2} variant="subtitle1" component="h4" fontWeight="bold" color={theme.palette.primary.main}>
          {subtitle}
        </Typography>
      )}
      {text && (
        <Typography variant="body2" fontWeight="bold">
          {text}
        </Typography>
      )}
    </SectionBox>
  );
}

export default EventCard;
