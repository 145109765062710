import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { profileSelector } from '../../../../store/participant/selectors';
import { reset } from '../../../../store/participant';
import { Navigate } from 'react-router-dom';
import ROUTES from '../../../../core/constants/routes.constant';

function SignOutScene() {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(profileSelector);

  useEffect(() => {
    if (profile) {
      dispatch(reset());
    }
  }, [dispatch, profile]);

  if (!profile) {
    return <Navigate to={ROUTES.auth} />;
  }

  return <>...</>;
}

export default SignOutScene;
